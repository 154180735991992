<script setup lang="ts">
const targetModelname = ref<HTMLElement | null>(null); // Modelname
const targetArticlenumber = ref<HTMLElement | null>(null); // Articlenumber
const targetEan = ref<HTMLElement | null>(null); // EAN

const isParkside = ref(false);
const hoverType = ref<number | null>(null);
const setHoverType = (val: number) => {
  hoverType.value = val;
};

const props = defineProps<{ isOpen?: boolean }>();

defineExpose({
  setHoverType,
});
</script>
<template>
  <SharedDetailsSummary
    :open="props.isOpen"
    :title="$t('spareParts.infoTitle')"
  >
    <div class="bg-white px-3 py-2">
      {{ $t("spareParts.infoContent") }}

      <div
        class="mx-auto flex flex-wrap flex-col xl:flex-row justify-center items-center xl:items-start xl:justify-between gap-10 mt-7"
      >
        <div class="order-2 xl:order-1 relative mx-auto">
          <div v-if="!isParkside">
            <img
              class="object-contain sm:w-458px sm:h-366px xl:block hidden mx-auto"
              src="../../../public/images/maschinenlabel.png"
              alt="spare-parts-info-1"
            />
            <img
              class="object-contain sm:w-458px sm:h-366px block xl:hidden"
              src="../../../public/images/maschinenlabel_responsive.png"
              alt="spare-parts-info-1"
            />
          </div>

          <!-- Parkside IMG-->
          <div v-else>
            <img
              class="hidden xl:block object-contain sm:w-458px sm:h-366px"
              src="../../../public/images/parkside_maschinenlabel.jpg"
              alt="spare-parts-parkside"
            />
            <img
              class="block xl:hidden object-contain sm:w-458px sm:h-366px"
              src="../../../public/images/parkside_maschinenlabel_responsive.png"
              alt="spare-parts-parkside"
            />

            <img
              src="../../../public/images/parkside_artikelnummer.png"
              class="hidden xl:block object-contain sm:w-458px"
            />
            <img
              src="../../../public/images/parkside_artikelnummer_responsive.png"
              class="block xl:hidden object-contain sm:w-458px"
            />
          </div>

          <!-- Trigger Modelname -->
          <div
            ref="targetModelname"
            class="absolute"
            :class="[
              hoverType === 1 ? 'block' : 'xl:hidden',
              !isParkside ? 'top-9px right-35px' : 'top-148px right-66px',
            ]"
          >
            <div class="hidden xl:flex items-center justify-center">
              <div
                class="block b-scheppach-primary-300 b-2"
                :class="[!isParkside ? 'w-130px h-34px' : 'w-130px h-24px']"
              ></div>
              <div class="b-b-2 b-scheppach-primary-300 w-20px"></div>
              <div
                class="flex bg-scheppach-primary-300 c-white w-32px h-32px inline-block rd-2xl items-center justify-center"
              >
                <p class="mb-1">1</p>
              </div>
            </div>
          </div>

          <!-- Trigger Articlenumber / IAN -->
          <div
            ref="targetArticlenumber"
            class="absolute"
            :class="[
              hoverType === 2 ? 'block' : 'xl:hidden',
              !isParkside ? 'top-34px right-42px' : 'top-170px right-101px',
            ]"
          >
            <div class="hidden xl:flex items-center justify-center">
              <div
                class="b-scheppach-primary-300 b-2"
                :class="[!isParkside ? 'w-110px h-21px' : 'w-95px h-18px']"
              ></div>
              <div class="b-b-2 b-scheppach-primary-300 w-20px"></div>
              <div
                class="flex bg-scheppach-primary-300 c-white w-32px h-32px inline-block rd-2xl items-center justify-center"
              >
                <p class="mb-1">2</p>
              </div>
            </div>
          </div>

          <!-- Trigger Seriennummer -->
          <div
            ref="targetSerialnumber"
            class="absolute block"
            :class="[
              hoverType === 3 ? 'block' : 'xl:hidden',
              !isParkside ? 'top-112px right-25px' : 'bottom-120px left-15px',
            ]"
          >
            <div class="hidden xl:flex items-center justify-center">
              <div
                class="flex bg-scheppach-primary-300 c-white w-32px h-32px inline-block rd-2xl items-center justify-center"
              >
                <p class="mb-1">3</p>
              </div>
              <div class="b-b-2 b-scheppach-primary-300 w-20px"></div>
              <div
                class="b-scheppach-primary-300 b-2"
                :class="[!isParkside ? 'w-175px h-21px' : 'w-122px h-24px']"
              ></div>
            </div>
          </div>

          <!-- Trigger Articlenumber Parkside -->
          <div
            ref="targetArticlenumber"
            class="absolute bottom-9px right--55px"
            :class="[hoverType === 5 ? 'block' : 'xl:hidden']"
          >
            <div class="hidden xl:flex items-center justify-center">
              <div class="b-scheppach-primary-300 b-2 w-160px h-34px"></div>
              <div class="b-b-2 b-scheppach-primary-300 w-20px"></div>
              <div
                class="flex bg-scheppach-primary-300 c-white w-32px h-32px rd-2xl items-center justify-center"
              >
                <p class="mb-1">5</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex sm:justify-center xl:justify-start flex-col gap-3 xl:mt-2 order-1 xl:order-2 w-full sm:w-450px xl:w-195px 2xl:w-300px mx-auto"
        >
          <!-- Hover MachineName -->
          <div
            class="relative xl:b xl:b-scheppach-primary-300 bg-scheppach-primary-500 xl:bg-white xl:hover:bg-scheppach-primary-50 c-white xl:c-scheppach-primary-500 py-1 text-center rd cursor-default"
            @mouseover="setHoverType(1)"
            @mouseout="setHoverType(0)"
          >
            <span class="absolute left-12px bottom-3px font-700 text-17px"
              >1</span
            >
            <div>
              {{ $t("product.modelName") }}
            </div>
          </div>

          <!-- Hover Articlenumber / IAN -->
          <div
            class="relative xl:b xl:b-scheppach-primary-300 bg-scheppach-primary-500 xl:bg-white xl:hover:bg-scheppach-primary-50 c-white xl:c-scheppach-primary-500 py-1 text-center rd cursor-default"
            @mouseover="setHoverType(2)"
            @mouseout="setHoverType(0)"
          >
            <span class="absolute left-12px bottom-3px font-700 text-17px"
              >2</span
            >
            <div>
              {{ !isParkside ? $t("product.articleNumber") : "IAN" }}
            </div>
          </div>

          <!-- Hover Seriennummer -->
          <div
            class="relative xl:b xl:b-scheppach-primary-300 bg-scheppach-primary-500 xl:bg-white xl:hover:bg-scheppach-primary-50 c-white xl:c-scheppach-primary-500 py-1 text-center rd cursor-default"
            @mouseover="setHoverType(3)"
            @mouseout="setHoverType(0)"
          >
            <span class="absolute left-12px bottom-3px font-700 text-17px"
              >3</span
            >
            <div>Seriennummer</div>
          </div>

          <!-- Hover EAN -->
          <div
            class="relative xl:b xl:b-scheppach-primary-300 bg-scheppach-primary-500 xl:bg-white xl:hover:bg-scheppach-primary-50 c-white xl:c-scheppach-primary-500 py-1 text-center rd cursor-default"
            @mouseover="setHoverType(4)"
            @mouseout="setHoverType(0)"
          >
            <span class="absolute left-12px bottom-3px font-700 text-17px"
              >4</span
            >
            <div>EAN</div>
          </div>

          <!-- Artikelnummer Parkside -->
          <div
            v-if="isParkside"
            class="relative xl:b xl:b-scheppach-primary-300 bg-scheppach-primary-500 xl:bg-white xl:hover:bg-scheppach-primary-50 c-white xl:c-scheppach-primary-500 py-1 text-center rd cursor-default"
            @mouseover="setHoverType(5)"
            @mouseout="setHoverType(0)"
          >
            <span class="absolute left-12px bottom-3px font-700 text-17px"
              >5</span
            >
            <div>Artikelnummer</div>
          </div>

          <div
            class="mt-5 mb-2 xl:mb-2 xl:b py-1 text-center rd cursor-pointer"
            :class="[
              !isParkside
                ? 'bg-#1e4b32 opacity-90 hover:opacity-100 c-white'
                : 'bg-scheppach-primary-700 hover:bg-scheppach-primary-700 c-white',
            ]"
            @click="isParkside = !isParkside"
          >
            <!-- Parkside color #1e4b32 -->
            <div>
              {{
                !isParkside
                  ? $t("spareParts.switchTo", { p: "Parkside" })
                  : $t("spareParts.switchTo", { p: "Scheppach" })
              }}
            </div>
          </div>
        </div>

        <div class="order-3 relative mx-auto">
          <img
            class="object-contain sm:w-458px sm:h-366px hidden xl:block"
            src="../../../public/images/ean.png"
            alt="spare-parts-info-2"
          />
          <img
            class="object-contain sm:w-458px sm:h-366px block xl:hidden"
            src="../../../public/images/ean_responsive.png"
            alt="spare-parts-info-2"
          />

          <!-- Trigger EAN -->
          <div
            ref="targetEan"
            class="absolute bottom-115px right-115px"
            :class="[hoverType === 4 ? 'block' : 'xl:hidden']"
          >
            <div class="hidden xl:flex items-center justify-center">
              <div
                class="flex bg-scheppach-primary-300 c-white w-32px h-32px rd-2xl items-center justify-center"
              >
                <p class="mb-1">4</p>
              </div>
              <div class="b-b-2 b-scheppach-primary-300 w-20px"></div>
              <div class="b-scheppach-primary-300 b-2 w-265px h-34px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SharedDetailsSummary>
</template>
